import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData, fetchUserData, logOut } from './reducer/user';
import ScrollToTop from './components/scrolltotop';
import WebsiteContainer from './components/websitecontainer';  
import { persistor } from './reducer/store'; // Import your Redux store and persistor
import Header from './components/header.js';

// Lazy load the components
const HomePage = lazy(() => import('./components/home'));
const LoginPage = lazy(() => import('./components/login'));
const About = lazy(() => import('./components/about'));
const MySubscription = lazy(() => import('./components/mysubscription'));
const MySubscriptionUpgrade = lazy(() => import('./components/mysubscriptionupgrade'));
const ContactUs = lazy(() => import('./components/contact'));
const HowItWorks = lazy(() => import('./components/howitworks'));
const AreYouReadyScreen = lazy(() => import('./components/areyouready'));
const ComingSoon = lazy(() => import('./components/comingsoon'));

class App extends Component {

  handleTokenExpiration() {
    const token = localStorage.getItem('authToken');
    const expirationTime = localStorage.getItem('authTokenExpiration');
  
    if (token && expirationTime) {

      if (Date.now() > Number(expirationTime)) {
        // Token has expired, log the user out
        localStorage.removeItem('authToken');
        localStorage.removeItem('authTokenExpiration');
        localStorage.removeItem('persist:root');
        localStorage.removeItem('userId');
        this.props.logOut(); 
        persistor.purge();
      }
    } else {
      this.props.logOut();
      persistor.purge();
    }
  };
  
  componentDidMount() {
    const { isLoggedIn, userData, fetchUserData } = this.props;

    const userId = localStorage.getItem('userId');

    localStorage.setItem('isLaunched', 'true');
    localStorage.setItem('isProd', 'true');

    this.handleTokenExpiration()
    if (isLoggedIn && userData && userData.email !== '' && userId) {
      fetchUserData(userId);
    }
  }

  isFeatureFlagEnabled = () => {
    return localStorage.getItem('isLaunched') === 'true';
  };

  render() {
    const { setUserData } = this.props;

    return (
      <Router>
        {this.isFeatureFlagEnabled() && <Header />}
        <WebsiteContainer>
          <Suspense fallback={<div>Loading...</div>}>
          <ScrollToTop />
            <Routes>
              {this.isFeatureFlagEnabled() ? (
                <>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage setUserData={setUserData} />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/mysubscription" element={<MySubscription setUserData={setUserData} />} />
                  <Route path="/mysubscription/upgrade" element={<MySubscriptionUpgrade />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/usersettings" element={<LoginPage setUserData={setUserData} />} />
                  <Route path="/howitworks" element={<HowItWorks />} />
                  <Route path="/areyouready" element={<AreYouReadyScreen />} />
                </>
              ) : (
                <Route path="*" element={<ComingSoon />} />
              )}
            </Routes>
          </Suspense>
        </WebsiteContainer>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  userData: state.user.userData,
});

const mapDispatchToProps = {
  setUserData,
  fetchUserData,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
