import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import store, { persistor } from './reducer/store';
import { Block } from 'baseui/block';
import App from './App';
import Clarity from "@microsoft/clarity";

Clarity.init("qpznoob7ee");

const GOOGLE_CLIENT_ID = "986389863913-5mb3r845khi3j22k92l4j88ci89ub4f1.apps.googleusercontent.com";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <GoogleOAuthProvider 
      clientId={GOOGLE_CLIENT_ID}
      onScriptLoadError={() => console.error("Google script failed to load")}
      options={{
        auto_select: false, // Prevents auto-select from showing the popup
        prompt: "select_account", // Forces manual selection without popup
      }}
    >
      <Block 
        style={{
          opacity: 1, // Remove the undefined fadeIn
          transition: 'opacity 1s ease-in',
        }}
      >
        <App />
      </Block>
    </GoogleOAuthProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);