import { Component } from 'react';
import { withRouter } from './withrouter'; 

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0); // Ensures scroll resets after React updates the page
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);